import React from 'react'
import './header.css'

const talk = () => {
  return (
    <div className="call-to-action">
    <a href='#contact' className='btn btn-primary'> Whats up?</a>
    <script async src="https://cse.google.com/cse.js?cx=11f932ecde9024196">
    </script>
    <div class="gcse-search"></div>
    </div>
  );
}

export default talk